<template>
  <footer class="m-footer">
    <ul class="bottom-ul" style="line-height: 30px">
      <li>Copyright © 2024 {{ icpData.name }} ￨</li>
      <li>
        <a class="link" href="http://beian.miit.gov.cn/" target="_blank">
          <!-- 浙ICP备2022035194号-2 -->
          {{ icpData.icp }}
        </a>
        <!-- ￨ -->
      </li>

      <!-- <li>
        <a class="link" href="https://vlive-static.vlebo.cn/wangwen.jpg" target="_blank">
          浙网文(2024)1392-064号
        </a>
      </li> -->
      <br />
      <li style="text-align: center">
        投诉通道：电话(400-6256-888) 、邮箱(vlive@vlebo.cn)
      </li>
    </ul>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      icpData: {
        name: '浙江微乐播信息科技有限公司',
        icp: '浙ICP备2022035194号',
        title: '浙江微乐播信息科技有限公司',
      },
    }
  },
  mounted() {
    const hostname = window.location.hostname

    switch (hostname) {
      case 'hainanweilebo.top':
        this.icpData = {
          name: '海南威乐播科技有限公司',
          icp: '琼ICP备2023003446号',
          title: '海南威乐播',
        }
        break
      case 'weileboo.top':
        this.icpData = {
          name: '广西南宁志捷网络科技有限公司',
          icp: '桂ICP备2022008688号-22',
          title: '广西南宁志捷网络科技有限公司',
        }
        break
      case 'weileboi.com.cn':
        this.icpData = {
          name: '广西南宁志捷网络科技有限公司',
          icp: '桂ICP备2022008688号-19',
          title: '广西南宁志捷网络科技有限公司',
        }
        break
      case 'hainweilebo.top':
        this.icpData = {
          name: '海南威乐播科技有限公司',
          icp: '琼ICP备2023003446号-3',
          title: '海南威乐播',
        }
        break
        case 'hzvlebo.top':
        this.icpData = {
          name: '浙江微乐播信息科技有限公司',
          icp: '浙ICP备2022035194号-7',
          title: '微乐播门户网站',
        }
        break
        case 'www.hzvlebo.top':
        this.icpData = {
          name: '浙江微乐播信息科技有限公司',
          icp: '浙ICP备2022035194号-7',
          title: '微乐播门户网站',
        }
        break
      default:
        this.icpData = {
          name: '浙江微乐播信息科技有限公司',
          icp: '浙ICP备2022035194号',
          title: '浙江微乐播信息科技有限公司',
        }
    }

    document.title = this.icpData.title
  },
  methods: {},
}
</script>

<style lang="scss" scoped></style>
<style lang="scss" scoped>
.m-footer {
  width: 100%;
  text-align: center;
  // position: absolute;
  bottom: 0;
  left: 0;
  font-size: 14px;
}

.bottom-ul {
  display: inline-block;
  width: 675px;
  height: 100px;
  line-height: 100px;
}

.bottom-ul li {
  float: left;
  list-style: none;
}

.link {
  margin-right: 0;
}
</style>
